import { Options } from "vue-class-component";
import components from "@/modules/flowable/common/form/ComponentExport";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "flowable-setting-form-design-render",
    components,
    props: {
        modelValue: {
            type: [String, Number, Array, Object],
            default: (): any => {
                return null;
            },
        },
        mode: {
            type: String,
            default: "DESIGN",
        },
        config: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    emits: ["update:modelValue"],
})
export default class FormDesignRender extends BaseFunction {
    private modelValue: any;
    get _value() {
        return this.modelValue;
    }
    set _value(val) {
        this.$emit("update:modelValue", val);
    }
    public validate(call: any) {
        (this.$refs.form as any).validate(call);
    }
}
