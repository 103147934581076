export default class ProcessTemplatesTableItem {
    templateId = "";
    formId = "";
    formName = "";
    groupId = 0;

    groupIdName = "";
    templateName = "";
    templateVersion = 0;
    /**
     * 摸板表单
     */
    formItems = "";
    settings = "";
    process = "";
    /**
     * 图标
     */
    icon = "";
    /**
     * 图标背景色
     */
    background = "";
    notify = "";
    /**
     * 谁能提交
     */
    whoCommit = "";
    /**
     * 谁能编辑
     */
    whoEdit = "";
    /**
     * 谁能导出数据
     */
    whoExport = "";
    remark = "";
    /**
     * 是否已停用
     */
    isStop = false;

    logo = "";
    processDefinitionId = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
