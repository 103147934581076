import { Options } from "vue-class-component";
import FormDesignRender from "../FormDesignRender/FormDesignRender.vue";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "flowable-setting-form-render",
    components: { FormDesignRender },
    props: {
        modelValue: {
            type: [String, Number, Array, Object],
            default: (): any => {
                return null;
            },
        },
        forms: {
            type: Array,
            default: [],
        },
    },
    emits: ["update:modelValue"],
})
export default class FormRender extends BaseFunction {
    private modelValue: any;
    private forms: any;
    private rules: any = {};
    created() {
        this.loadFormConfig(this.forms);
    }
    get _value() {
        return this.modelValue;
    }
    set _value(val) {
        this.$emit("update:modelValue", val);
    }
    public validate(call: any) {
        let success = true;
        (this.$refs.form as any).validate((valid: any) => {
            success = valid;
            if (valid) {
                //校验成功再校验内部
                for (let i = 0; i < this.forms.length; i++) {
                    if (this.forms[i].name === "TableList") {
                        const formRef = this.$refs[`sub-item_${this.forms[i].id}`];
                        if (formRef && Array.isArray(formRef) && formRef.length > 0) {
                            formRef[0].validate((subValid: any) => {
                                success = subValid;
                            });
                            if (!success) {
                                break;
                            }
                        }
                    }
                }
            }
            call(success);
        });
    }
    public loadFormConfig(forms: any) {
        forms.forEach((item: any) => {
            if (item.name === "SpanLayout") {
                this.loadFormConfig(item.props.items);
            } else {
                //this.$set(this._value, item.id, this.value[item.id]);
                if (item.props.required) {
                    this.rules[item.id] = [
                        {
                            type: item.valueType === "Array" ? "array" : undefined,
                            required: true,
                            message: `请填写${item.title}`,
                            trigger: "blur",
                        },
                    ];
                }
            }
        });
    }
}
