import baseApi from "@/api/baseApi";
import requests from "@/utils/axios";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/flowable/process/templates",
    // 获取模版（带定义ID）
    getTemplate(query: any) {
        return requests.post(this.modulesUrl + "/getTemplate", query);
    },
    // 发起流程
    startProcess(query: any) {
        return requests.post(this.modulesUrl + "/startProcess", query);
    },
};
