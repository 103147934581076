import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import ProcessTemplatesTableItem from "@/entity/flowable/ProcessTemplatesTableItem";
import FormInitiateProcess from "@/modules/flowable/setting/form-setting/FormInitiateProcess/FormInitiateProcess.vue";
import API from "@/api/flowable/process-templates";

@Options({
    name: "app-flowable-process-templates",
    components: {
        "app-draggable-listview": DraggableListview,
        "form-initiate-process": FormInitiateProcess,
    },
})
export default class ProcessTemplates extends BaseTableMixins<ProcessTemplatesTableItem> implements Partial<BaseTableInterface<ProcessTemplatesTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.editTableItem = new ProcessTemplatesTableItem();
        this.editModalItem = new ProcessTemplatesTableItem();
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改模版 (" + this.editModalItem.templateName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.templateId)) return "模版ID不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.templateName)) return "模版名称不能为空！";
    }

    //打开模版管理
    public openProcessDesign(code: string) {
        const query: any = code.length > 0 ? { code } : {};
        this.$router
            .push({
                path: "/flowable/process/design",
                query,
            })
            .then(() => {});
    }

    //打开发起流程
    private formModalTitle = "";
    private formModalCode = "";
    protected formModalOpen(item: ProcessTemplatesTableItem): void {
        this.formModalTitle = `发起流程 (${item.templateName})`;
        this.formModalCode = item.templateId;
        (this.$refs.formModal as any).openModal();
    }

    //发起流程
    protected async formModalSubmit(): Promise<boolean> {
        const formData = (this.$refs.processForm as any).getFormData();
        const form = (this.$refs.processForm as any).getForm();
        // console.log("formData", formData);
        // console.log("form", form);
        return await new Promise(async (resolve, reject) => {
            (this.$refs.processForm as any).validate(async (valid: boolean) => {
                if (valid) {
                    await API.startProcess({
                        processDefinitionId: form.processDefinitionId,
                        formData,
                    }).then((res: any) => {
                        console.log("startProcess", res);
                        this.toast.success("流程发布成功！");
                    });
                    resolve(true);
                } else {
                    this.toast.error("流程发布失败，请先完善表单！");
                    reject(false);
                }
            });
        });
    }
}
