import { Options } from "vue-class-component";
import FormRender from "../FormRender/FormRender.vue";
import BaseFunction from "@/mixins/BaseFunction";
import API from "@/api/flowable/process-templates";

@Options({
    name: "flowable-setting-initiate-process",
    components: {
        FormRender,
    },
    props: {
        code: {
            type: String,
            required: true,
        },
    },
})
export default class FormInitiateProcess extends BaseFunction {
    private code = "";
    private loading = false;
    private formData: any = {};
    private form: any = {
        formId: "",
        formName: "",
        logo: {},
        formItems: [],
        process: {},
        remark: "",
    };
    public mounted() {
        this.loadFormInfo(this.code);
    }

    get forms() {
        return this.setup.formItems;
    }

    get setup(): any {
        return this.$store.getters["flowable/design"];
    }

    set setup(val: any) {
        this.$store.dispatch("flowable/setDesign", val).then();
    }

    public loadFormInfo(templateId: any) {
        this.loading = true;
        API.getTemplate({ templateId }).then((rsp: any) => {
            this.loading = false;
            //console.log(rsp.data);
            const form = rsp.data;
            form.logo = JSON.parse(form.logo);
            form.settings = JSON.parse(form.settings);
            form.formItems = JSON.parse(form.formItems);
            form.process = JSON.parse(form.process);
            this.form = form;
            //构建表单及校验规则
            this.setup = form;
        });
    }
    public validate(call: any) {
        (this.$refs.formRender as any).validate(call);
    }
    public getFormData() {
        return this.formData;
    }
    public getForm() {
        return this.form;
    }
}
